import React, { CSSProperties } from 'react'
import s from './CustomBtnTransparent.module.css'
import login_arrow from '../../../assets/img/log_in_arrow.svg'
import backArrow from '../../../assets/img/arrow_left.svg'

type PropsType = {
  title: string
  onClick?: () => void
  isLogin?: boolean
  isBack?: boolean
  style?: CSSProperties
}
const CustomBtnTransparent: React.FC<PropsType> = ({
  isBack,
  title,
  isLogin,
  ...props
}) => {
  return (
    <button className={s.my_btn} {...props}>
      {isLogin ? <img src={login_arrow} alt='' /> : <></>}
      {isBack ? <img src={backArrow} alt='' /> : <></>}
      {title}
    </button>
  )
}

export default CustomBtnTransparent

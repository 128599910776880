import React from 'react'
import s from './Home.module.css'
import CustomCard from '../../components/ui/customCard/CustomCard'
import CustomBtnTransparent from '../../components/ui/customBtnTransparent/CustomBtnTransparent'
import phone from '../../assets/img/phone.png'
import mainBG from '../../assets/img/main_page_bg.png'
import main_img_mobile from '../../assets/img/mobile/main_bg_mobile.png'
import arrow_main_screen from '../../assets/img/main_screen_arrow.svg'
import CustomBtnPink from '../../components/ui/customBtnPink/CustomBtnPink'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  ABOUT_US,
  CARDS,
  COMMERCIAL_BANKING,
  DIGITAL_ASSETS_BANKING,
  OPEN_ACCOUNT,
  PRIVATE_BANKING,
  TRADE_FINANCE,
} from '../../router/Constants'
import { useFormik } from 'formik'
import { sendFormDataThunk } from '../../store/reducers/ActionCreators'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import MyPopupSendSuccess from '../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess'
import { motion } from 'framer-motion'

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isSuccess: boolean | null = useAppSelector(
    state => state.userReducer.isMessageSentSuccess
  )

  const formikMail = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: values => {
      const data = {
        subject: 'Get Started',
        data: values,
      }
      dispatch(sendFormDataThunk(data))
    },
  })

  const cards = [
    {
      title: 'Private Banking',
      short: 'PB',
      description:
        'The hallmark of Private Banking is exemplary personal, bespoke service. No two clients are the ' +
        'same, so flexibility is a cornerstone. Tell us about the banking service you need, and your Wall Street Bank' +
        ' International Private Banker will put it in place. Not even challenging tasks can fluster us. ',
      link: PRIVATE_BANKING,
    },
    {
      title: 'Commercial Banking',
      short: 'CB',
      description:
        'Businesses can also benefit from the private banking touch! If you are looking for a reliable ' +
        'global trade partner to help you handle transactions and create wealth across borders, talk to our expert ' +
        'bankers in the areas of complex payment transactions and trade finance.',
      link: COMMERCIAL_BANKING,
    },
    {
      title: 'Cards',
      short: 'C',
      description:
        'At Wall Street Ibu we allow you to protect, access and grow your wealth from anywhere in the' +
        ' world with an international bank account in a safe jurisdiction. To access your funds on the move, you ' +
        'will receive one or more global debit cards for seamless transactions.',
      link: CARDS,
    },
    {
      title: 'Digital Assets Banking',
      short: 'DAB',
      description:
        'We provide comprehensive banking services for both established and startup companies from the ' +
        'blockchain and cryptocurrency world, as well as offering private banking services to high net worth ' +
        'individuals who are trading or investing in crypto assets. ',
      link: DIGITAL_ASSETS_BANKING,
    },
    {
      title: 'Trade Finance',
      short: 'TF',
      description:
        'As a Wall Street Ibu corporate account holder, you are guaranteed direct contact with a Trade' +
        ' Finance and Documentary Credit expert. This way our clients have their instruments endorsed by prime ' +
        'banks without the undoubted inconvenience of having to deal with the large institutions.',
      link: TRADE_FINANCE,
    },
  ]

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        {isSuccess ? <MyPopupSendSuccess /> : <></>}

        <div className={s.content}>
          <div className={s.main_screen}>
            <div>
              <img className={s.main_bg} src={mainBG} alt='' />
            </div>
            <div className={s.main_title_block}>
              <div className={s.trusted}>Trusted</div>
              <div className={s.partner}>partner</div>
              <div className={s.for_your}>
                for your <img src={arrow_main_screen} alt='' />
              </div>
              <div className={s.digital_journey}>digital journey!</div>
              <div className={s.my_input_block}>
                <form onSubmit={formikMail.handleSubmit}>
                  <input
                    type='email'
                    className={s.my_input}
                    placeholder={'Enter your e-mail'}
                    id='email'
                    name='email'
                    required
                    onChange={formikMail.handleChange}
                    value={formikMail.values.email}
                  />
                  <div className={s.main_btn_block}>
                    <CustomBtnPink title={'Get Started'} type={'submit'} />
                  </div>
                </form>
              </div>
            </div>
            <div className={s.main_gradient}>
              <div className={s.gradient_text}>
                Our team of experienced international private bankers operates
                digitally, providing you with seamless, near-24/7 service across
                the globe. We leverage technology to provide efficient and
                secure digital banking solutions.
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.main_screen_mobile}>
            <div className={s.title_block}>
              <div className={s.title_mobile}>Trusted</div>
              <div className={s.title_mobile_margin}>partner</div>
              <div className={s.title_mobile}>
                for your
                <div>
                  <img
                    className={s.arrow_main_screen_mobile}
                    src={arrow_main_screen}
                    alt=''
                  />
                </div>
              </div>
              <div className={s.title_italic}>digital journey!</div>
            </div>

            <div className={s.main_img_mobile_block}>
              <img className={s.main_img_mobile} src={main_img_mobile} alt='' />
            </div>

            <div className={s.main_gradient_mobile}>
              <div className={s.gradient_text_mobile}>
                Our team of experienced international private bankers operates
                digitally, providing you with seamless, near-24/7 service across
                the globe. We leverage technology to provide efficient and
                secure digital banking solutions.
              </div>
            </div>

            <div className={s.input_wrapper}>
              <div className={s.my_input_block}>
                <input
                  type='text'
                  className={s.my_input}
                  placeholder={'Enter your e-mail'}
                />
                <div className={s.main_btn_block}>
                  <CustomBtnPink title={'Get Started'} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.who_we}>
          <div className={s.content}>
            <div className={s.who_title}>WHO are we?</div>
            <div className={s.who_we_desc_block}>
              <div className={s.who_we_description}>
                <div>
                  <span className={s.color}>Wall Street Ibu</span> is an
                  international private bank &nbsp; serving the unique needs of
                  globally mobile and digitally active citizens, their families
                  and their businesses.
                </div>
                <div>
                  It is a thoroughly modern International Private Banking brand
                  that boasts a long history and a long-term view of the future.
                </div>
                <div>
                  The strength of our bank lies in the diversity of our
                  International Private Bankers.
                </div>
                <div>
                  Our team speaks a dozen languages and uses its skills to serve
                  private and institutional customers by offering personalized
                  relationships and individual financial solutions.
                </div>
              </div>
              <div className={s.btn_block}>
                <NavLink
                  to={ABOUT_US}
                  reloadDocument
                  style={{ textDecoration: 'none' }}>
                  <CustomBtnTransparent
                    title={'Read More'}
                    style={{ color: '#FFE556', borderColor: '#FFE556' }}
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.cards_wrapper}>
            <div className={s.cards_title}>Services we provide</div>
            {cards.map(card => {
              return (
                <div className={s.card_block} key={card.title}>
                  <NavLink to={card.link} reloadDocument>
                    <CustomCard
                      title={card.title}
                      short={card.short}
                      description={card.description}
                    />
                  </NavLink>
                </div>
              )
            })}
          </div>
        </div>

        <div className={s.content}>
          <div className={s.open_acc_block}>
            <div className={s.open_account_title}>Wall Street Ibu</div>
            <div className={s.open_account_desc}>
              is an International Private Bank &nbsp; serving the unique needs
              of globally mobile and digitally active citizens, their families
              and their businesses.
            </div>
            <div className={s.open_account_desc_mobile}>
              is an International Private Bank
              <br />
              serving the unique needs of globally <br />
              mobile and digitally <br />
              active citizens, <br />
              their families <br />
              and their businesses.
            </div>
            <div className={s.btn_open_account}>
              <CustomBtnPink
                title={'Open Account'}
                onClick={() => navigate(OPEN_ACCOUNT)}
              />
            </div>
            {/* <img src={phone} className={s.phone} alt='' /> */}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Home

import React, {useEffect, useLayoutEffect} from 'react';
import s from './Details.module.css'
import SelectSmall from "../../components/ui/MySelect/MySelect";
import {GetAccountsThunk} from "../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {accountsArrayType, userDataType} from "../../store/reducers/UserSlice";
import {useSortedAccounts} from "../../hooks/useSortedAccounts";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import MyPopupNewAccount from "../../components/ui/MyPopupNewAccount/MyPopupNewAccount";

const Details = () => {

    const dispatch = useAppDispatch()

    const userToken: string = useAppSelector(state => state.userReducer.user.userToken)

    useLayoutEffect(() => {
        console.log("get")
        dispatch(GetAccountsThunk(userToken))

    }, [])

    const userData: userDataType = useAppSelector(state => state.userReducer.user.userData)
    const userAccountsList: accountsArrayType[] = useAppSelector(state => state.userReducer.userAccounts)

    const [selectedCurrency, setSelectedCurrency] = React.useState("ALL");
    const [newAccountPopupIsShow, setNewAccountPopupIsShow] = React.useState(false);
    const sortedAccounts = useSortedAccounts(userAccountsList, selectedCurrency)

    const getAccountsListCurrency = () => {
        return userAccountsList?.map((acc) => {
            return acc.currency
        });
    }

    const currencyArr = getAccountsListCurrency()
    currencyArr?.push("ALL")

    return (
        <div className={s.container}>
            {
                newAccountPopupIsShow
                    ? <MyPopupNewAccount setNewAccountPopupIsShow={setNewAccountPopupIsShow}/>
                    : <></>
            }
            <div className={s.client_details_wrapper}>
                <div className={s.title}>
                    Client details
                </div>
                <div className={s.client_info}>
                    <div className={s.block}>
                        <div className={s.row}>
                            <div className={s.field_name}>Client ID:</div>
                            <div className={s.field_value}>{userData?.clientId}</div>
                        </div>
                        <div className={s.row}>
                            <div className={s.field_name}>Client Name:</div>
                            <div className={s.field_value}>{userData?.firstName} {userData?.lastName}</div>
                        </div>
                    </div>
                    <div className={s.block}>
                        <div className={s.row}>
                            <div className={s.field_name}>Company Name:</div>
                            <div className={s.field_value}>{userData?.companyName}</div>
                        </div>
                        <div className={s.row}>
                            <div className={s.field_name}>Contact Details:</div>
                            <div className={s.field_value}>{userData?.email}</div>
                        </div>
                    </div>
                </div>
                <div className={s.client_details_btn_block}>
                    <div className={s.add_account_button_wrapper}>
                        <CustomBtnPink title={"New Account"}
                                       isAddAccount
                                       onClick={() => setNewAccountPopupIsShow(true)}
                        />
                    </div>
                </div>
            </div>

            <div className={s.accounts}>

                <div className={s.accounts_table}>
                    <div className={s.table_header}>
                        <div className={s.header_item_long}>Account Number</div>
                        <div className={s.header_item_long}>Account Type</div>
                        <div className={s.header_item_currency}>
                            <span className={s.currency}>Currency</span>
                            <SelectSmall selectedItem={selectedCurrency}
                                         setSelectedItem={setSelectedCurrency}
                                         item={currencyArr}
                                         width={70}
                                         height={32}
                            />
                        </div>
                        <div className={s.header_item_short}>Account Balance</div>
                        <div className={s.header_item_short}>Security Deposit</div>
                        <div className={s.header_item_status}>Status</div>
                    </div>
                    <div className={s.row_wrapper}>
                        {
                            sortedAccounts?.map((acc: any) => <div className={s.table_row} key={acc.number}>
                                <div className={s.header_item_long}>{acc.number}</div>
                                <div className={s.header_item_long}>{acc.type}</div>
                                <div className={s.header_item_currency}>
                                    {acc.currency}
                                </div>
                                <div className={s.header_item_short}>{acc.balance}</div>
                                <div className={s.header_item_short}>{acc.minBalance}</div>
                                <div className={s.header_item_status}>{acc.isActive ? "Active" : "Inactive"}</div>
                            </div>)
                        }
                    </div>
                    <div className={s.table_footer}>
                        <div className={s.accounts_counter}>
                            1 - {sortedAccounts?.length} of {sortedAccounts?.length}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;

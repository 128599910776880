import React from 'react'
import s from './AboutUs.module.css'
import puzzles from '../../assets/img/puzzless.png'
import puzzlesMobile from '../../assets/img/mobile/puzzless.png'
import coin from '../../assets/img/bitcoin.png'
import CustomBtnPink from '../../components/ui/customBtnPink/CustomBtnPink'
import { OPEN_ACCOUNT } from '../../router/Constants'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const AboutUs = () => {
  const navigate = useNavigate()

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        <div className={s.content}>
          <div className={s.title}>about us</div>
        </div>

        <div className={s.gradient_block}>
          Wall Street Ibu Bank is an international private bank!
        </div>

        <div className={s.content}>
          <div className={s.flex}>
            <div className={s.left}>
              <h3>A Foundation of Strength</h3>
              For over two decades, our predecessors have built a reputation for
              confidence, reliability, and strategic partnerships. In July 2022,
              we embarked on a new chapter with the merger of two pillars of
              <span className={s.bold}> financial expertise:</span>{' '}
              <span className={s.bold}>
                Jilin Jiutai Rural Commercial Bank Co., Ltd.
              </span>{' '}
              , a leading Chinese commercial Bank with a deep history, and{' '}
              <span className={s.bold}>MC Financial Services Limited</span>, a
              global financial force with roots in the Asia Pacific region. This
              union, driven by a shared vision of international growth, created
              Wall Street Bank to deliver a new standard of international
              financial services.
            </div>
            <div className={s.right}>
              <h3>Global Reach, Digital Excellence</h3>
              <div className={s.mb}>
                The Bank is legally established in Fomboni, Comoros Islands, a
                premier offshore finance hub.
              </div>
              <div className={s.mb}>
                Our team of experienced international private bankers operates
                digitally, providing you with seamless, near-24/7 service across
                the globe. We leverage technology to provide efficient and
                secure digital banking solutions.
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className={s.puzzles_block}>
            <div className={s.flex}>
              <div className={s.puzzles_img_block}>
                <img className={s.puzzles_mob} src={puzzlesMobile} alt='' />
                <img className={s.puzzles} src={puzzles} alt='' />
              </div>
              <div className={s.puzzles_description_block}>
                <h2 className={s.puzzles_block_title}>
                  Your Global Financial Partner
                </h2>
                <div className={s.puzzles_description_content}>
                  Wall Street Bank is designed for individuals and businesses
                  seeking flexible financial solutions in a connected world. Our
                  offshore accounts offer:
                </div>
                <div className={s.puzzles_description_content}>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>Unparalleled Flexibility: </span>
                    Tailored solutions to meet your unique needs, with a focus
                    on international opportunities.
                  </div>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>
                      Enhanced Privacy & Security:{' '}
                    </span>
                    Protect your assets and financial information with our
                    robust offshore framework and commitment to confidentiality.
                  </div>
                  <div className={s.puzzles_description_row}>
                    <span className={s.bold}>Strategic Advantages: </span>
                    Benefit from a stable and secure environment, ideal for
                    managing and growing your global wealth.
                  </div>
                </div>
                <div className={s.puzzles_button_block}>
                  <div className={s.button_wrapper}>
                    <CustomBtnPink
                      title={'Sign Up'}
                      onClick={() => navigate(OPEN_ACCOUNT)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div>
            <h2>Specializing in the Future of Finance</h2>
            <div className={s.content_row}>
              We are at the forefront of the evolving financial landscape,
              offering specialized services that encompass the digital age:
            </div>
            <div className={s.flex}>
              <div className={s.left}>
                <span className={s.bold}>Digital Asset Escrow: </span>Securely
                manage your digital assets with our robust escrow services,
                designed for the evolving financial landscape.
              </div>
              <div className={s.right}>
                <span className={s.bold}>Global Real Estate Financing: </span>
                Access financing for prime properties in major cities and resort
                destinations worldwide, making your real estate dreams a
                reality.
              </div>
            </div>
          </div>
        </div>

        <div className={s.coin_block}>
          <div className={s.content}>
            <div className={s.text}>
              <div>
                Wall Street Bank – Your trusted partner for international
                financial success.
              </div>
              <div>
                Contact us today to learn more and explore how we can help you
                reach your financial goals.
              </div>
              <div className={s.coin_container}>
                <img src={coin} alt='' />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default AboutUs

import React from 'react';
import {Layout, Space} from 'antd';
import s from './PersonalAccount.module.css'
import logo from '../assets/img/logo_white.png'
import logoMobile from '../assets/img/logo_white.png'
import {NavLink, Outlet, useNavigate} from 'react-router-dom';
import './index.css'
import classnames from "classnames";
import {
    CHANGE_PASSWORD,
    DETAILS,
    EXCHANGE,
    INTRA_TRANSFERS,
    STATEMENT,
    TRANSFERS
} from "../router/Constants";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {LogoutThunk} from "../store/reducers/ActionCreators";
import {userDataType} from "../store/reducers/UserSlice";
import MyPopupTransactionSuccess from "../components/ui/MyPopupTransactionSuccess/MyPopupTransactionSuccess";
import MyPopupTransactionRejected from "../components/ui/MyPopupTransactionRejected/MyPopupTransactionRejected";
import CustomBtnTransparentPersonalAccount
    from "../components/ui/customBtnTransparentPersonalAccount/CustomBtnTransparentPersonalAccount";

const {Header, Sider, Content, Footer} = Layout;

const headerStyle: React.CSSProperties = {
    color: '#303539',
    height: 96,
    lineHeight: '64px',
    backgroundColor: '#ACAEAF',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 24px'
};

const headerStyleMobile: React.CSSProperties = {
    color: '#303539',
    height: 52,
    lineHeight: '52px',
    backgroundColor: '#122932',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0',
    position: 'fixed',
    width: '100vw',
    zIndex: '999999'
};

const contentStyle: React.CSSProperties = {
    minHeight: '91vh',
    color: '#000',
    backgroundColor: '#ACAEAF',
    padding: '36px 32px 0'
};

const contentStyleMobile: React.CSSProperties = {
    minHeight: '91vh',
    color: '#000',
    backgroundColor: '#ACAEAF',
    padding: '72px 0 32px'
};

const siderStyle: React.CSSProperties = {
    position: 'relative',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#303539',
    flex: '0 0 184px',
    zIndex: 1,
};

const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#303539',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    position: 'fixed',
    bottom: '0',
    width: '100%'
};

const PersonalAccount = () => {

    const userData:userDataType = useAppSelector( state => state.userReducer.user.userData)
    const isTransactionWasSendSuccess: boolean | null = useAppSelector( state => state.userReducer.transactionWasSendSuccess)

    const navigate = useNavigate();

    const dispatch = useAppDispatch()

    const handleLogout = () => {
        dispatch(LogoutThunk())
            .then(() => {
                navigate("/");
            })
    }

    const isShowSuccessPopup = () => {
        if (isTransactionWasSendSuccess) {
            return <MyPopupTransactionSuccess/>
        }
    }

    const isShowRejectPopup = () => {
        if (isTransactionWasSendSuccess === false) {
            return <MyPopupTransactionRejected/>
        }
    }

    return (
        <div>

            { isShowSuccessPopup() }
            { isShowRejectPopup() }
            <div className={s.wrapper_dekstop}>
                <Space direction="vertical" style={{width: '100%', position: "relative"}} size={[0, 0]}>
                    <Layout>
                        <Sider style={siderStyle} width={184}>
                            <img src={logo} alt="" className={s.logo}/>

                            <NavLink to={DETAILS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_details"></div>
                                </div>
                                <div className="text_block">
                                    Account <br/>
                                    Details
                                </div>
                            </NavLink>

                            <NavLink to={STATEMENT}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_statements"></div>
                                </div>
                                <div className="text_block">
                                    Statements
                                </div>
                            </NavLink>
                            <NavLink to={TRANSFERS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_transfers"></div>
                                </div>
                                <div className="text_block">
                                    SWIFT/SEPA <br/>
                                    Transfers
                                </div>
                            </NavLink>
                            <NavLink to={INTRA_TRANSFERS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_intra"></div>
                                </div>
                                <div className="text_block">
                                    Internal <br/>
                                    Transfers
                                </div>
                            </NavLink>
                            <NavLink to={EXCHANGE}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_exchange"></div>
                                </div>
                                <div className="text_block">
                                    Currency <br/>
                                    Exchange
                                </div>
                            </NavLink>
                            <div className={s.all_rights}>
                                <div>© 2024</div>
                                <div>Wall Street Ibu</div>
                            </div>
                        </Sider>
                        <Layout>
                            <div className={s.header_wrapper}>
                                <Header style={headerStyle}>
                                    <div className={s.left}>
                                        <NavLink to={CHANGE_PASSWORD}
                                                 className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                            <div className={s.welcome_block}>
                                                <span className={s.personal_name}>Change password</span>
                                            </div>
                                        </NavLink>
                                        <div className={s.welcome_block}>
                                            <span className={s.personal_name}>{userData.salutation} {userData.firstName} {userData.lastName}</span>
                                        </div>
                                    </div>
                                    <div className={s.right}>
                                        <div className={s.btn_block}>
                                            <CustomBtnTransparentPersonalAccount
                                                title={"Log Out"}
                                                onClick={handleLogout}
                                                isLogout
                                            />
                                        </div>
                                    </div>
                                </Header>
                            </div>
                            <Content style={contentStyle}>
                                <Outlet/>
                            </Content>
                        </Layout>
                    </Layout>
                </Space>
            </div>

            <div className={s.wrapper_mobile}>
                <Space direction="vertical" style={{width: '100%', position: "relative"}} size={[0, 0]}>
                    <Layout>

                        <Header style={headerStyleMobile}>
                            <div className={s.flex}>
                                <div className={s.logo_block_mobile}>
                                    <img className={s.logo_mob} src={logoMobile} alt=""/>
                                </div>
                                <div className={s.user_name_mobile}>
                                    {userData.salutation} {userData.firstName} {userData.lastName}
                                </div>
                            </div>
                            <div className={s.btn_block}>
                                <CustomBtnTransparentPersonalAccount onClick={handleLogout} title={"Log Out"} isLogout/>
                            </div>

                        </Header>

                        <Content style={contentStyleMobile}>
                            <Outlet/>
                        </Content>

                        <Footer style={footerStyle}>
                            <NavLink to={DETAILS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_details"></div>
                                </div>
                                <div className="text_block">
                                    Account <br/>
                                    Details
                                </div>
                            </NavLink>

                            <NavLink to={STATEMENT}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_statements"></div>
                                </div>
                                <div className="text_block">
                                    Statements
                                </div>
                            </NavLink>
                            <NavLink to={TRANSFERS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_transfers"></div>
                                </div>
                                <div className="text_block">
                                    SWIFT/SEPA <br/>
                                    Transfers
                                </div>
                            </NavLink>
                            <NavLink to={INTRA_TRANSFERS}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_intra"></div>
                                </div>
                                <div className="text_block">
                                    Internal <br/>
                                    Transfers
                                </div>
                            </NavLink>
                            <NavLink to={EXCHANGE}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_exchange"></div>
                                </div>
                                <div className="text_block">
                                    Currency <br/>
                                    Exchange
                                </div>
                            </NavLink>
                            <NavLink to={CHANGE_PASSWORD}
                                     className={({isActive}) => classnames(s.nav_item, isActive ? "activePersonalNav" : 'inactivePersonalNav')}>
                                <div className="img_block">
                                    <div className="img_lock"></div>
                                </div>
                                <div className="text_block_password">
                                    Change<br/>
                                    Password
                                </div>
                            </NavLink>
                        </Footer>

                    </Layout>
                </Space>
            </div>

        </div>
    );
};

export default PersonalAccount;

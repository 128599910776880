import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import s from './CustomSelectWithMui.module.css'
import {ChangeEvent} from "react";
import {InputLabel} from "@mui/material";

type propsType = {
    item?: (string | null)[]
    width: number
    height: number
    handleChange?: { (e: ChangeEvent<any>): void; <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void; }
    selectedItem?: string
    placeholder?: string
    id: string
    name: string
}

export default function CustomSelectWithMui(props: propsType) {

    return (
        <FormControl sx={{m: 1, minWidth: props.width, height: props.height}} size="small"
                     className={s.my_select_wrapper}>
            <InputLabel className={s.label} id="demo-select-small">{props.placeholder}</InputLabel>
            <Select
                labelId="demo-select-small"
                id={props.id}
                name={props.name}
                label={props.placeholder}
                defaultValue={"Please select"}
                value={props.selectedItem}
                onChange={props.handleChange}
                className={s.my_select}
            >
                {
                    props.item?.map((i: any) => {
                        return (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    );
}

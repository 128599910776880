import React from 'react'
import s from './Contact.module.css'
import mailIcon from '../../assets/img/icon_mail.svg'
import phoneIcon from '../../assets/img/icon_phone.svg'
import ContactUsForm from '../../components/contactUsForm/ContactUsForm'
import MyPopupSendSuccess from '../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess'
import { useAppSelector } from '../../hooks/redux'
import { motion } from 'framer-motion'
import addressIcon from '../../assets/img/addressIcon.svg'
import whatsapp from '../../assets/img/whatsapp_gray.svg'
import telegram from '../../assets/img/telegram_gray.svg'

const Contact = () => {
  const isSuccess: boolean | null = useAppSelector(
    state => state.userReducer.isMessageSentSuccess
  )

  return (
    <div className={s.wrapper}>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={s.motion_div}>
        {isSuccess ? <MyPopupSendSuccess /> : <></>}

        <div className={s.content}>
          <div className={s.title}>Contact</div>

          <div className={s.contacts_flex_block}>
            <div className={s.left}>
              <div className={s.text}>
                You can contact us by mail or phone number. Or you can leave
                your contact details and our managers will contact you
              </div>

              <div className={s.address_block}>
                <img src={addressIcon} width={24} height={24} alt='' />
                <div className={s.mail}>
                  Representative Office: <br />
                  No. 802 Renmin Avenue, Changchun City, Jilin Province
                </div>
              </div>
              <div className={s.address_block}>
                <img src={addressIcon} width={24} height={24} alt='' />
                <div className={s.mail}>
                  15 Christaki Kranou, Germasogeia, Limassol, Cyprus
                </div>
              </div>

              {/*<div className={s.address_block}>
                                <img src={addressIcon} width={24} height={24} alt=""/>
                                <div className={s.mail}>Representative Office: <br/>
                                    Business center Sadovaya Plaza, CAO, SK SeverTverskoy, Dolgorukovskaya street, 7, Moscow, Russia
                                </div>
                            </div>*/}

              <div className={s.mail_block}>
                <img src={mailIcon} alt='' />
                <a className={s.mail} href='mailto:info@wallstreetibu.com'>
                  info@wallstreetibu.com
                </a>
              </div>
              <div className={s.mail_block}>
                <img src={phoneIcon} alt='' />
                <div className={s.mail}>+35724020246</div>
              </div>

              <div className={s.mobile_additional_information}>
                {/*<div className={s.additional_content}>*/}
                {/*    */}
                {/*</div>*/}
                <div className={s.additional_content}>SWIFT: WSBOKMK1</div>
              </div>
            </div>
            <div className={s.contact_form}>
              <ContactUsForm />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Contact
